@tailwind base;
@tailwind components;
@tailwind utilities;

.toast .bold {
  font-weight: 700;
}

div[data-radix-portal] {
  z-index: 2147483646 !important;
}

.booking-tab[data-state="active"] {
  color: #333333;
  font-weight: 600;
  box-shadow: inset 0 -1px 0 0 #333333, 0 1px 0 0 #333333;
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes overlayAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlayContentShow {
  from {
    opacity: 0;
    -webkit-transform: translateY(15px);
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(-0px);
  }
}

.react-datetime-picker__wrapper {
  border-radius: 5px;
  padding: 0.5rem;
  width: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .float-animate {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: backwards;
    will-change: transform, opacity;
  }

  .float-animate[data-side="top"] {
    animation-name: slideDownAndFade;
  }
  .float-animate[data-side="right"] {
    animation-name: slideLeftAndFade;
  }
  .float-animate[data-side="bottom"] {
    animation-name: slideUpAndFade;
  }
  .float-animate[data-side="left"] {
    animation-name: slideRightAndFade;
  }

  .overlay-animate {
    will-change: transform, opacity;
    animation: overlayShow 250ms cubic-bezier(0.16, 1, 0.3, 1) backwards;
  }

  .overlay-content-animate {
    animation: overlayContentShow 250ms cubic-bezier(0.16, 1, 0.3, 1) backwards;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.react-tel-input {
  font-family: "SF Pro Text", sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

/* libre-baskerville-regular - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-regular.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

/* libre-baskerville-italic - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-italic.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

/* libre-baskerville-700 - latin */
@font-face {
  font-family: "Libre Baskerville";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("../public/assets/fonts/libre/libre-baskerville-v14-latin-700.svg#LibreBaskerville")
      format("svg"); /* Legacy iOS */
}

/* SF Pro Text 700 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-bold.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-bold.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-bold.ttf")
      format("truetype");
}

/* SF Pro Text 800 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-heavy.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-heavy.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-heavy.ttf")
      format("truetype");
}

/* SF Pro Text 300 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-light.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-light.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-light.ttf")
      format("truetype");
}

/* SF Pro Text 600 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-medium.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-medium.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-medium.ttf")
      format("truetype");
}

/* SF Pro Text 400 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-regular.ttf")
      format("truetype");
}

/* SF Pro Text 500 */
@font-face {
  font-family: "SF Pro Text";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("../public/assets/fonts/sf-pro-text/sf-pro-text-semibold.eot"); /* IE9 Compat Modes */
  src: local(""),
    /* Super Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-semibold.woff")
      format("woff"),
    /* Modern Browsers */
      url("../public/assets/fonts/sf-pro-text/sf-pro-text-semibold.ttf")
      format("truetype");
}
